<template>
    <div>
        <v-card elevation="0" max-width="1000px" style="margin: auto;border-radius: 0px;min-height: 670px">
            <div class="payment-header justify-center d-flex" v-if="step_1">
                <v-btn width="40%" @click="nextStep1" class="pay-now-btn" color="#223A6B" height="45px" style="color: white !important;font-size: 18px" v-if="paynow">
                    Pay Now
                </v-btn>
                <v-btn width="30%" class="pay-with-btn" @click="payWith" color="#ffffff" height="45px" style="margin-right: 25px;color: #223A6B !important;font-size: 18px" v-if="!paynow">
                    Pay With <img src="@/assets/logo/paypal.png" width="30px" alt="">
                </v-btn>
                <v-btn width="30%" class="payment-type-btn" color="#223A6B" height="45px" style="color: white !important;font-size: 18px" v-if="!paynow">
                    Change Payment Type
                </v-btn>
            </div>
            <v-card-title class="d-block" style="background: #3C599D;color: white !important;padding: 70px 60px" v-if="step_1">
                <v-row>
                    <v-col cols="12" md="6" lg="6" sm="12" class="d-block">
                        <div style="font-size: 51px;font-weight: 900"><span style="font-weight: 300 !important">IN</span>VOICE</div>
                        <div style="font-weight: 300;font-size: 18px;padding-top: 5px">Invoice No. #456</div>
                    </v-col>
                    <spacer />
                    <v-col cols="12" md="6" lg="6" sm="12" class="d-block">
                        <div class="d-flex justify-center">
                            <img width="40%" src="@/assets/logo/mini-white.png" alt="">
                        </div>
                        <div class="d-flex justify-center">
                            <img width="50%" src="@/assets/logo/logo-white.png" alt="">
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" style="font-weight: 300;font-size: 18px">INVOICE TO</v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4" lg="4" sm="12">
                        <div style="font-weight: 700;font-size: 30px">Thomas Adthos</div>
                        <div style="font-weight: 300;font-size: 18px;">CREATIVE DIRECTOR</div>
                        <div class="row" style="background: black;margin-top: 15px">
                            <v-col cols="12" sm="12" md="5" lg="5" class="total-due">
                                <div style="font-size: 15px;font-weight: 400">TOTAL DUE:</div>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" style="align-self: center">
                                <div style="font-size: 17px">$55,555,555</div>
                            </v-col>
                        </div>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="12">
                        <div>
                            <v-icon color="white" size="20px">mdi-phone</v-icon> 
                            <span style="font-size: 13px;font-weight: 300;padding-left: 10px">email@email.com</span>
                        </div>
                        <div>
                            <v-icon color="white" size="20px">mdi-message</v-icon> 
                            <span style="font-size: 13px;font-weight: 300;padding-left: 10px">+1-222-3333-444</span>
                        </div>
                        <div>
                            <v-icon color="white" size="20px">mdi-map-marker</v-icon> 
                            <span style="font-size: 13px;font-weight: 300;padding-left: 10px">6587 West media spancer usa-6574</span>
                        </div>
                    </v-col>
                    <v-divider vertical style="border-color: white" />
                    <v-col cols="12" md="4" lg="4" sm="12">
                        <div class="d-flex" style="font-size: 17px;">
                            <div style="font-weight: 300">Invoice Date&nbsp;</div>
                            <div>22.11.2020</div>
                        </div>
                        <div class="d-flex" style="font-size: 17px;">
                            <div style="font-weight: 300">Account No. #8734512</div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-subtitle v-if="step_1">
                <v-row class="justify-center">
                    <v-data-table
                        :headers="headers"
                        :items="desserts"
                        style="width: 90%"
                        hide-default-footer
                        class="elevation-1 payment-table"
                    ></v-data-table>
                </v-row>
                <v-row class="discount-section">
                    <v-col cols="12" md="5" sm="12" lg="5">
                        <div style="font-size: 22px;font-weight: 700;color: black;padding-bottom: 10px;padding-top: 10px">Discounts(s) Applied</div>
                        <table>
                            <tr>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <td style="padding-right: 25px"><span style="color: red;font-weight: 800">1)</span> 25% off Plan Fee</td>
                                <td>$225.00</td>
                            </tr>
                        </table>
                        <div class="d-block" style="padding-top: 30px">
                            <div style="font-size: 22px;font-weight: 700;color: black">Payment Methods</div>
                            <div class="row" style="margin-top: 10px">
                                <v-col cols="12" md="6" lg="6" sm="12">
                                    <div style="font-weight: 500;font-size: 20px;color: rgb(30 30 30)">PAYPAL</div>
                                    <div style="font-size: 13px;">infodomain@email.com</div>
                                    <span class="d-flex" style="width: 30%;background: red;height: 2px"></span>
                                </v-col>
                                <v-col cols="12" md="6" lg="6" sm="12">
                                    <div style="font-weight: 500;font-size: 20px;color: rgb(30 30 30)">SKRILL</div>
                                    <div style="font-size: 13px;">infodomain@email.com</div>
                                    <span class="d-flex" style="width: 30%;background: red;height: 2px"></span>
                                </v-col>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="7" sm="12" lg="7" style="padding: 10px 0 0 0;border-left: 2px solid #dddddd;border-right: 2px solid #dddddd">
                        <table width="100%">
                            <tr>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr style="height: 30px">
                                <th style="font-size: 18px" class="text-right">Subtotal:</th>
                                <th style="font-size: 18px">$1500.00</th>
                            </tr>
                            <tr style="height: 30px">
                                <th style="font-size: 18px" class="text-right">Discount:</th>
                                <th style="font-size: 18px">$450.00</th>
                            </tr>
                            <tr style="height: 30px">
                                <th style="font-size: 18px" class="text-right">Total:</th>
                                <th style="font-size: 18px">$1950.00</th>
                            </tr>
                        </table>
                        <table style="background: #3C599D;color: white;margin-top: 20px" width="100%" height="80px">
                            <tr>
                                <th class="text-right">PAID</th>
                                <th style="width: 50%">$0.00</th>
                            </tr>
                            <tr>
                                <th class="text-right" style="margin-top:-20px !important">DUE</th>
                                <th>$1500.00</th>
                            </tr>
                        </table>
                    </v-col>
                    <v-col></v-col>
                </v-row>
                <v-row class="payment-info">
                    <v-col cols="12" md="6" sm="12" lg="6">
                        <div class="d-flex" style="font-size: 25px;font-weight: 800;color: black;padding-bottom: 10px">Payment Address</div>
                        <div>442 Paris Hill Avenue, Park Street</div>
                        <div>Valparaso, IN 4567</div>
                    </v-col>
                    <v-col cols="12" md="6" sm="12" lg="6">
                        <div class="d-flex" style="font-size: 25px;font-weight: 800;color: black">Terms & Conditions</div>
                        <div class="d-flex" style="padding-top: 10px">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem velit impedit necessitatibus in consequuntur praesentium error suscipit nulla similique deserunt?</div>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <v-card-title v-if="step_2">
                <div class="row">
                    <v-col cols="12" md="4" lg="4" sm="12">
                        <div>
                            <label class="payment-label">Please select payment method</label>
                            <v-select
                            :items="payment_method"
                            class="payment-method"
                            placeholder="Visa"
                            dense
                            outlined
                            ></v-select>
                        </div>
                        <div>
                            <label class="payment-label">Card Number</label>
                            <v-text-field
                            dense
                            outlined
                            ></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="12" class="align-self-end">
                        <div class="row">
                            <v-col cols="12" md="7" lg="7" sm="12">
                                <div class="step-2-date-title">Expiration Date</div>
                                <v-row>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-text-field 
                                        style="width: 100%"
                                        dense
                                        outlined
                                        placeholder="--"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-text-field 
                                        style="width: 100%"
                                        dense
                                        placeholder="----"
                                        outlined
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="5" lg="5" sm="12">
                                <div class="step-2-date-title">Security Code</div>
                                <v-text-field
                                dense
                                style="width: 70%"
                                outlined
                                ></v-text-field>
                            </v-col>
                        </div>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="12" style="border: 1px solid #CBCBCB;padding: 40px 35px;display: block;align-self: center;border-radius: 10px">
                        <div style="color: #223A6B;font-size: 16px;line-height: 20px;">
                            We accept the following secure payment methods:
                        </div>
                        <div style="margin-top: 15px" class="d-flex align-center">
                            <img src="@/assets/logo/paypal.png" width="70px" height="40px" alt="" style="margin-right: 15px">
                            <img src="@/assets/logo/visa.png" height="30px" width="70px" alt="">
                            <img src="@/assets/logo/master_card.png" alt="" width="70px" height="40px" style="margin-right: 15px">
                        </div>
                    </v-col>
                </div>
                <div class="row">
                    <v-col cols="12" md="4" lg="4" sm="12">
                        <div class="billing-information-title">BILLING INFORMATION</div>
                        <div>
                            <label class="payment-label">First Name</label>
                            <v-text-field
                            dense
                            outlined
                            ></v-text-field>
                        </div>
                        <div>
                            <label class="payment-label">Billing Address</label>
                            <v-text-field
                            dense
                            outlined
                            ></v-text-field>
                        </div>
                        <div>
                            <label class="payment-label">Billing Address, line 2</label>
                            <v-text-field
                            dense
                            outlined
                            ></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="12" style="margin-top: 42px">
                        <div>
                            <label class="payment-label">Last Name</label>
                            <v-text-field
                            dense
                            outlined
                            ></v-text-field>
                        </div>
                        <div>
                            <label class="payment-label">Zip or postal code</label>
                            <v-text-field
                            dense
                            outlined
                            ></v-text-field>
                        </div>
                        <div>
                            <label class="payment-label">Country</label>
                            <v-select
                            :items="country"
                            class="payment-method"
                            placeholder="Phillippines"
                            dense
                            outlined
                            ></v-select>
                        </div>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="12" style="margin-top: 42px">
                        <div>
                            <label class="payment-label">City</label>
                            <v-text-field
                            dense
                            outlined
                            ></v-text-field>
                        </div>
                        <div>
                            <label class="payment-label">Phone number</label>
                            <v-text-field
                            dense
                            outlined
                            ></v-text-field>
                        </div>
                        <div class="d-flex justify-center align-center" style="height: 95px">
                            <v-btn width="140px" height="40px" color="#223A6B" style="color: white !important;font-size: 14px;font-weight: bold;text-transform: capitalize" @click="nextStep2">Continue</v-btn>
                        </div>
                    </v-col>
                </div>
                <div class="row">
                    <v-checkbox
                    v-model="save_payment"
                    label="Save my payment information so checkout is easy next time"
                    color="#223A6B"
                    class="save-payment"
                    ></v-checkbox>
                </div>
            </v-card-title>
            <v-card-title v-if="step_3">
                <v-row>
                    <v-col cols="12" md="8" lg="8" sm="12">
                        <label class="payment-label">Please select payment method</label>
                        <v-select
                        :items="payment_method"
                        class="payment-method"
                        style="width: 50%"
                        placeholder="Paypal"
                        dense
                        outlined
                        ></v-select>
                        <div class="step-3-desc" style="margin-bottom: 30px">
                            PayPal transactions are authorized through the PayPal web site. Click the button below to open a new web browser window to initiate the transaction.
                        </div>
                        <div class="step-3-desc">
                            You'll have a chance to review your order before it's placed.
                        </div>
                        <div class="text-right" style="margin-top: 20px">
                            <v-btn width="140px" height="40px" color="#223A6B" style="color: white !important;font-size: 14px;font-weight: bold;text-transform: capitalize" @click="nextStep3">Continue</v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="12" style="border: 1px solid #CBCBCB;padding: 40px 35px;display: block;align-self: end;border-radius: 10px">
                        <div style="color: #223A6B;font-size: 16px;line-height: 20px;">
                            We accept the following secure payment methods:
                        </div>
                        <div style="margin-top: 15px" class="d-flex align-center">
                            <img src="@/assets/logo/paypal.png" width="70px" height="40px" alt="" style="margin-right: 15px">
                            <img src="@/assets/logo/visa.png" height="30px" width="70px" alt="">
                            <img src="@/assets/logo/master_card.png" alt="" width="70px" height="40px" style="margin-right: 15px">
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-title v-if="final_payment">
                <v-row>
                    <v-col cols="12" md="8" lg="8" sm="12" style="color: #223A6B" class="text-center">
                        <div style="font-size: 30px">Thank you for your business</div>
                        <div style="font-size: 14px">If you have any questions, please let us know. We'll back to you as soon as we can</div>
                        <div>
                            <img style="margin-top: 10px" src="https://dwdqz3611m4qq.cloudfront.net/templates/Freelancer-Invoice-Template2-DB.jpg" width="90%" alt="">
                        </div>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="12" class="text-center">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="transparent"
                                dark
                                width="170px"
                                elevation="0"
                                style="color: #223A6B;border: 1px solid #223A6B !important;text-transform: capitalize;margin-top: 30px"
                                v-bind="attrs"
                                v-on="on"
                                >
                                More Actions
                                <v-icon right>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                v-for="(item, index) in more_actions"
                                :key="index"
                                >
                                <v-list-item-title><v-icon>{{ item.icon }}</v-icon>&nbsp; {{ item.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-title>
        </v-card>
    </div>
</template>

<script>
    export default {
        name: 'Payment',
        data: () => ({
            step_1: true,
            step_2: false,
            step_3: false,
            final_payment: false,
            save_payment: true,
            paynow: true,
            more_actions: [
                {
                    title: 'Download as PDF',
                    icon: 'mdi-download'
                },
                {
                    title: 'Print',
                    icon: 'mdi-printer'
                },
                
            ],
            payment_method: [
                'Visa',
                'Master Card',
                'Paypal',
                'Stripe'
            ],
            country: [
                'Philippines',
                'United States',
                'Ukrane',
                'Malysia'
            ],
            headers: [
                {
                    text: 'Service Name',
                    align: 'start',
                    sortable: false,
                    width: '319',
                    value: 'name',
                },
                { text: 'Unit Price', align: 'center', value: 'price', width: '150', },
                { text: 'Quantity', align: 'center', width: '150', value: 'quantity' },
                { text: 'Total', align: 'center', width: '150', value: 'total' },
                ],
            desserts: [
                {
                    name: 'Local Seo',
                    price: '$159',
                    quantity: '02',
                    total: '$300',
                },
                {
                    name: 'LPO',
                    price: '$159',
                    quantity: '02',
                    total: '$300',
                },
                {
                    name: 'Power Content',
                    price: '$159',
                    quantity: '02',
                    total: '$300',
                },
                
            ],
        }),
        methods: {
            nextStep1() {
                this.step_1 = false
                this.step_2 = true
            },
            nextStep2() {
                this.step_2 = false
                this.step_3 = true
            },
            nextStep3() {
                this.step_3 = false
                this.step_1 = true
                this.paynow = false
            },
            payWith() {
                this.step_1 = false
                this.final_payment = true
            }
        }
    }
</script>
<style scoped>
>>> .payment-table .v-data-table-header {
    background: #1BA6DF !important;
}
>>> .payment-table .v-data-table-header th span{
    color: white !important;
    font-size: 17px;
    font-weight: 600 !important;
}
>>> .payment-table tbody td {
    font-size: 19px;
    font-weight: 700;
}
>>> .payment-method .v-select__selections input::placeholder,
>>> .payment-method .v-select__selections .v-select__selection {
    color: #223A6B;
}
>>> .payment-table table td + td { 
    border-left: 2px solid #dddddd; 
}
.save-payment {
    padding: 15px;
    margin-top: 0;
}
>>> .save-payment label {
    color: #223A6B !important;
    font-weight: 900 !important;
}
.step-2-date-title {
    font-size: 15px;
    color: #223A6B !important;
}
</style>
<style lang="scss" scoped>
.payment-header {
    margin-bottom: 15px;
}
.payment-label {
    color: #223A6B;
    font-size: 16px;
}
.billing-information-title { 
    margin-bottom: 10px;
    color: #223A6B;
}
.discount-section {
    padding: 0px 48px
}
.payment-info {
    padding: 0 50px;
}
.total-due {
    background: #1BA6DF;
    margin: 10px
}
.step-3-desc {
    color: #223A6B;
    font-size: 16px;
    line-height: 21px;
}
@media all and (min-width: 530px) and (max-width: 960px) {
    .discount-section,
    .payment-info {
        padding: 0 !important;
    }
    .total-due {
        margin: 0 !important;
    }
}
@media all and (max-width: 530px) {
    .discount-section,
    .payment-info {
        padding: 0 !important;
    }
    .payment-header {
        display: block !important;
        text-align: center;
    }
    .pay-with-btn,
    .payment-type-btn {
        display: flex !important;
        width: 100% !important;
        margin: 20px 0;
    }
    .total-due {
        margin: 0 !important;
    }
}
</style>